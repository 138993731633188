import { GvEnvironment } from '@pv-frontend/gift-voucher-journey';

export interface EnvInterface extends GvEnvironment {
  clientId: string;
  projectId: string;
  apiUrlBsV1: string;
  apiUrlBsV1Internal: string;
  apiUrlLsV1: string;
  corePath: string;
  corePathV2: string;
  offerPath: string;
  offerPathV2: string;
  countryId: string;
  urlsToBeRemoved: string[];
  campaignPath: string;
  campaignId: string; // COMMENT: since this project has only one campaign, will be using it from env, so we don't have to make an extra api call
  sentryDsn: string;
  production: boolean;
}

export const environment: EnvInterface = {
  production: false,
  apiPerksServiceUrl: 'https://sandbox-api.poshvine.com/ps/v1',
  apiPerksServiceUrlV2: 'https://sandbox-api.poshvine.com/ps/v2',
  apiClientsServiceUrl: 'https://sandbox-api.poshvine.com/cs/v1',
  apiUrlPaV1: 'https://sandbox-api.poshvine.com/pa/v1/',
  apiBookingServiceUrl: 'https://sandbox-api.poshvine.com/bs/v1',
  apiUrlBsV1: 'https://sandbox-api.poshvine.com/bs/v1/',
  clientId: 'e9d3e6ae-e76c-4e2c-aa0d-e27e0fd27444',
  projectId: 'd6b75845-2c83-40bb-8aba-ac272e3eb991',
  // clientId: '3388bbd3-7e9b-4575-84f3-a0e53a6c4ace',
  // projectId: 'd00ef9d9-da1b-49eb-b174-008cce00bacf',
  apiUrlBsV1Internal: 'https://sandbox-api.poshvine.com/bs/internal',
  apiUrlLsV1: 'https://sandbox-api.poshvine.com/ls/v1/',
  corePath: 'https://sandbox-offerservice.poshvine.com/core_service/v1',
  corePathV2: 'https://sandbox-api.poshvine.com/cs/v1',
  offerPath: 'https://sandbox-offerservice.poshvine.com/offer_service/v1',
  offerPathV2: 'https://sandbox-api.poshvine.com/ps/v1',
  countryId: 'efb468c6-2d84-4caa-a826-ff0ab253d0c6',
  campaignPath: 'https://sandbox-api.poshvine.com/cas/v1/',
  urlsToBeRemoved: [''],
  apiLoyaltyServiceUrl: '',
  campaignId: '96dd3e76-c85c-4894-9c91-a3ffa0e19903',
  apiBookingServiceUrlV2: '',
  sentryDsn:
    'https://bf90c28acfbebfd2e116f00b079ea0f4@o515678.ingest.us.sentry.io/4507454481367040',
};
